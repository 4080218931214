import React from "react";
import Helmet from "react-helmet";
import firebase from "gatsby-plugin-firebase";
import Layout from "../layout";
import SEO from "../components/SEO";
import ViewCounter from "../components/ViewCounter";

const initialData = [
  {
    title: "Building REST APIs with Flask and Python",
    description:
      "Master the art of creating robust APIs with Flask and Python.",
    url: "https://www.udemy.com/course/building-rest-apis-with-flask-and-python/",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/pratap22.appspot.com/o/courses%2Frest-api-flask.png?alt=media",
    buttonLabel: "View Course",
  },
  {
    title: "Git Crash Course 2023",
    description:
      "Elevate your Git skills in 2023 with this fast-paced crash course.",
    url: "https://youtu.be/-D20B6TSbes",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/pratap22.appspot.com/o/courses%2Fgit.jpeg?alt=media",
    buttonLabel: "View Course",
  },
];

const CourseItem = ({ course, key }) => (
  <div className="course" key={key}>
    <img src={course.thumbnail} alt="Pratap" />
    <div className="description">
      <h3>{course.title}</h3>
      <p>{course.description}</p>
      <a
        className="view-all"
        href={course.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {course.buttonLabel}
      </a>
    </div>
  </div>
);

const CoursesPage = () => {
  const [courses, setCourses] = React.useState(initialData);

  React.useEffect(() => {
    firebase
      .database()
      .ref("courses")
      .once("value")
      .then((snapshot) => {
        setCourses(snapshot.val() || []);
      });
  }, []);

  return (
    <Layout>
      <SEO />
      <Helmet title={`Courses : Learn With Pratap`} />
      <span style={{ display: "none" }}>
        <ViewCounter id="courses" />
      </span>
      <div className="courses-container">
        <h1 style={{ textAlign: "center" }}>All Courses</h1>
        <div className="courses">
          {courses.map((course, index) => (
            <CourseItem course={course} key={`course-${index}`} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default CoursesPage;
